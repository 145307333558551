import React, { useEffect, useState } from 'react';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { Button, message, Modal } from 'antd';
import { remove } from 'lodash';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAdmin, removeAdmin } from 'store/global/action';

// Components
import CreateManager from 'components/CreateManager';
import EditManager from 'components/EditManager';
import Loading from 'components/Loading';

// Interfaces
import { IQueryParam } from 'interfaces/common.interface';

// Constants
import { GLOBAL_EVENT, INIT_PAGINATION } from 'constants/common.constant';

// Helpers
import { getUniqueKey } from 'helpers/common.helper';

// Enums
import { EAdminRole } from 'enums/common.enum';

import './Manager.scss';

const Manager = () => {
  const dispatch = useAppDispatch();

  const { event } = useAppSelector((store) => store.global);

  const [admin, setAdmin] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IQueryParam>({
    page: INIT_PAGINATION.PAGE,
    limit: INIT_PAGINATION.LIMIT,
  });
  const [pagination, setPagination] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showCreateManager, setShowCreateManager] = useState<boolean>(false);
  const [showDeleteManager, setShowDeleteManager] = useState<boolean>(false);
  const [showEditManager, setShowEditManager] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [editId, setEditId] = useState<any>(null);

  const fetchAdmin = async () => {
    try {
      setLoading(true);
      const res: any = await unwrapResult(await dispatch(getAdmin(params)));

      if (res && res.items && res.pagination) {
        setAdmin([...admin, ...res.items]);
        setPagination(res.pagination);
      } else {
        setAdmin([...[]]);
        setPagination(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreAdmin = () => {
    if (params.page && params.page < pagination.totalPages) {
      setParams({ ...params, page: params.page + 1 });
    }
  };

  const handleCreateSuccess = () => {
    setShowCreateManager(false);
  };

  const resetData = () => {
    setAdmin([...[]]);
    setPagination(null);
  };

  const resetRemoveModal = () => {
    setDeleteId(null);
    setShowDeleteManager(false);
  };

  const handleRemoveManager = (id: string) => {
    setDeleteId(id);
    setShowDeleteManager(true);
  };

  const handleCancelRemoveManager = () => {
    resetRemoveModal();
  };

  const handleOkRemoveManager = async () => {
    try {
      await unwrapResult(await dispatch(removeAdmin(deleteId)));
      remove(admin, (item) => item._id === deleteId);
      setAdmin([...admin]);
      message.success('Deleted admin successfully');
      resetRemoveModal();
    } catch (e) {
      console.log(e);
    }
  };

  const resetEditModal = () => {
    setEditId(null);
    setShowEditManager(false);
  };

  const handleCancelEditManager = () => {
    resetEditModal();
  };

  const handleEditManager = (id: string) => {
    setEditId(id);
    setShowEditManager(true);
  };

  const handleEditSuccess = () => {
    resetEditModal();
    resetData();
    setParams({
      page: INIT_PAGINATION.PAGE,
      limit: INIT_PAGINATION.LIMIT,
    });
  };

  useEffect(() => {
    if (params) {
      fetchAdmin();
    }
  }, [params]);

  useEffect(() => {
    if (pagination) {
      if (pagination.currentPage < pagination.totalPages) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [pagination]);

  useEffect(() => {
    if (event && event.type) {
      switch (event.type) {
        case GLOBAL_EVENT.RELOAD_ADMIN: {
          resetData();
          setParams({
            page: INIT_PAGINATION.PAGE,
            limit: INIT_PAGINATION.LIMIT,
          });
          break;
        }
      }
    }
  }, [event]);

  return (
    <>
      <div className="content manager-content">
        <Row>
          <Col md="12">
            <div className="manager-content__create">
              <Button
                className="btn btn-primary action-btn"
                onClick={() => setShowCreateManager(true)}
              >
                Create Manager
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            {admin && admin.length ? (
              <Card>
                <CardBody>
                  <InfiniteScroll
                    dataLength={admin.length}
                    next={fetchMoreAdmin}
                    hasMore={hasMore}
                    loader={null}
                    endMessage={
                      <p className="all-items-txt">You have seen all items</p>
                    }
                  >
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Created At</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {admin.map((item, index) => (
                          <tr key={getUniqueKey()}>
                            <td>{item.fullName}</td>
                            <td>{item.email}</td>
                            <td>
                              {item.role === EAdminRole.Admin && 'Admin'}
                              {item.role === EAdminRole.SuperAdmin &&
                                'Super Admin'}
                            </td>
                            <td>
                              {moment(item.createdAt).format('YYYY-MM-DD')}
                            </td>
                            <td className="text-right action">
                              {item.role === EAdminRole.Admin && (
                                <>
                                  <i
                                    className="fas fa-pen"
                                    onClick={() => handleEditManager(item._id)}
                                  ></i>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() =>
                                      handleRemoveManager(item._id)
                                    }
                                  ></i>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </CardBody>
              </Card>
            ) : (
              <p className="all-items-txt">No items.</p>
            )}

            {loading && <Loading />}
          </Col>
        </Row>
      </div>

      <Modal
        title="Create Manager"
        open={showCreateManager}
        onCancel={() => setShowCreateManager(false)}
        footer={null}
      >
        <CreateManager onSuccess={handleCreateSuccess} />
      </Modal>

      {editId && (
        <Modal
          title="Edit Manager"
          open={showEditManager}
          onCancel={handleCancelEditManager}
          footer={null}
        >
          <EditManager id={editId} onSuccess={handleEditSuccess} />
        </Modal>
      )}

      <Modal
        title="Delete"
        open={showDeleteManager}
        onOk={handleOkRemoveManager}
        onCancel={handleCancelRemoveManager}
        footer={[
          <Button
            key="back"
            className="btn modal-btn"
            onClick={handleCancelRemoveManager}
          >
            Cancel
          </Button>,
          <Button
            className="btn btn-primary modal-btn"
            key="submit"
            onClick={handleOkRemoveManager}
          >
            OK
          </Button>,
        ]}
      >
        <p>Do you want to remove this manager?</p>
      </Modal>
    </>
  );
};

export default Manager;
