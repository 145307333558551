import { createReducer } from '@reduxjs/toolkit';
import { IEvent } from 'interfaces/common.interface';
import { event, storeMe } from 'store/global/action';

export interface IState {
  event: IEvent | any;
  me: any;
}

const initialState: IState = {
  event: null,
  me: undefined,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(event, (state, action) => {
      state.event = action.payload;
    })
    .addCase(storeMe, (state, action) => {
      state.me = action.payload;
    });
});
