import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';

let ps: any;

type IProps = {
  bgColor?: string;
  activeColor?: string;
  routes?: any[];
};

const Sidebar = (props: IProps) => {
  const location = useLocation();
  const sidebar = useRef(null);

  const activeRoute = (routeName: any) => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar?.current!, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a href="/" className="simple-text logo-normal">
          <img alt="image" src={require('assets/img/logo.png')} />
          <span>Beem</span>
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes && props.routes.length
            ? props.routes.map((item: any, key: any) => {
                return (
                  <li
                    className={
                      activeRoute(item.path) + (item.pro ? ' active-pro' : '')
                    }
                    key={key}
                  >
                    <NavLink to={item.path} className="nav-NavLink">
                      <i className={item.icon} />
                      <p>{item.name}</p>
                    </NavLink>
                  </li>
                );
              })
            : null}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
