import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { Input } from 'antd';
import { debounce } from 'lodash';

// Components
import Loading from 'components/Loading';

// Interfaces
import { IQueryParam } from 'interfaces/common.interface';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import { getSystemLog } from 'store/global/action';

// Constants
import { INIT_PAGINATION } from 'constants/common.constant';

// Helpers
import { getUniqueKey } from 'helpers/common.helper';

const SystemLog = () => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IQueryParam>({
    page: INIT_PAGINATION.PAGE,
    limit: INIT_PAGINATION.LIMIT,
  });
  const [pagination, setPagination] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      setLoading(true);

      const res: any = await unwrapResult(await dispatch(getSystemLog(params)));

      if (res && res.items && res.pagination) {
        setData([...data, ...res.items]);
        setPagination(res.pagination);
      } else {
        setData([...[]]);
        setPagination(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreData = () => {
    if (params.page && params.page < pagination.totalPages) {
      setParams({ ...params, page: params.page + 1 });
    }
  };

  const resetData = () => {
    setData([...[]]);
    setPagination(null);
  };

  const searching = (e: any) => {
    resetData();

    if (e.target.value.trim() !== '') {
      setParams({
        ...params,
        keyword: e.target.value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    } else {
      setParams({
        ...params,
        keyword: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    }
  };

  useEffect(() => {
    if (params) {
      fetchData();
    }
  }, [params]);

  useEffect(() => {
    if (pagination) {
      if (pagination.currentPage < pagination.totalPages) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [pagination]);

  return (
    <>
      <div className="content user-content">
        <Row>
          <div className="multiple-fields">
            <div className="multiple-fields__item">
              <Input
                className="default"
                placeholder="Search..."
                allowClear
                onChange={debounce(searching, 1000)}
              />
            </div>
          </div>
        </Row>

        <Row>
          <Col md="12">
            {data && data.length ? (
              <Card>
                <CardBody>
                  <InfiniteScroll
                    dataLength={data.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={null}
                    endMessage={
                      <p className="all-items-txt">You have seen all items</p>
                    }
                  >
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Created At</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((item, index) => (
                          <tr key={getUniqueKey()}>
                            <td className="link">
                              <a href={`user/${item._id}`}>{item.title}</a>
                            </td>

                            <td>{item.description}</td>

                            <td>
                              {moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </CardBody>
              </Card>
            ) : (
              <p className="all-items-txt">No items.</p>
            )}

            {loading && <Loading />}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SystemLog;
