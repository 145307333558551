import axios from 'axios';
import { message } from 'antd';

// Helpers
import { getToken } from 'helpers/common.helper';

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

HTTP.interceptors.request.use((req) => {
  const token = getToken();
  if (token && req.headers) {
    req.headers['Authorization'] = `Bearer ${token}`;
  }
  return req;
});

const showError = (error?: any) => {
  if (error) {
    if (typeof error === 'string') {
      message.error(error);
    } else {
      message.error('Something went wrong');
    }
  } else {
    message.error('Something went wrong');
  }
};

HTTP.interceptors.response.use(
  (res) => {
    return res.data.data;
  },
  (err) => {
    if (err && err.response && err.response.status === 400) {
      showError(err.response.data.message);
    }

    if (err && err.response && err.response.status === 500) {
      showError();
    }

    throw err;
  }
);

export default HTTP;
