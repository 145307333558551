import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Private = (Component: any) => {
  const WithPrivate = ({ props }: { props: any }) => {
    const navigate = useNavigate();

    useEffect(() => {
      if (props && props.me === null) {
        navigate('/login');
      }
    }, [props]);

    return <Component {...props} />;
  };

  return WithPrivate;
};
