import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Radio } from 'antd';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import {
  createAdmin,
  emitEvent,
  getAdminDetail,
  updateAdmin,
} from 'store/global/action';

// Constants
import { GLOBAL_EVENT } from 'constants/common.constant';

// Enums
import { EAdminRole } from 'enums/common.enum';

export interface IProps {
  id: string;
  onSuccess: () => void;
}

const CreateManager = ({ id, onSuccess }: IProps) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [admin, setAdmin] = useState<any>(null);

  const onSubmit = async (values: any) => {
    const valid = await form.validateFields();
    if (!valid) return;

    if (values.email) {
      delete values.email;
    }

    if (
      values.password === '' ||
      !values.password ||
      values.password === undefined
    ) {
      delete values.password;
    }

    try {
      setLoading(true);
      await unwrapResult(await dispatch(updateAdmin({ id, values })));
      message.success('Updated admin successfully');
      onSuccess();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdminDetail = async (id: string) => {
    try {
      const res: any = await unwrapResult(await dispatch(getAdminDetail(id)));
      setAdmin(res);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id && id !== '') {
      fetchAdminDetail(id);
    }
  }, [id]);

  return (
    <div className="create-manager-content custom-modal-content">
      <div className="create-manager-content__form">
        {admin && (
          <Form
            name="basic"
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
            className="default"
            form={form}
            initialValues={admin}
          >
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                { required: true, message: 'Full name is required' },
                { min: 3, message: 'Full name must be at least 3 characters' },
                {
                  max: 100,
                  message: 'Full name must be no more than 100 characters',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Email" name="email">
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { min: 8, message: 'Password must be at least 8 characters' },
                {
                  max: 100,
                  message: 'Password must be no more than 100 characters',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item label="Admin Type" name="role">
              <Radio.Group>
                <Radio value={EAdminRole.Admin}>Admin</Radio>
                <Radio value={EAdminRole.SuperAdmin}>Super Admin</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item>
              <Button
                className="btn btn-primary"
                htmlType="submit"
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default CreateManager;
