import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import './Image.scss';

interface IProps {
  styles: any;
  source: string;
}

const Image = ({ styles, source }: IProps) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="image-content" style={styles}>
      <img
        alt="photo"
        style={styles}
        src={source}
        onLoad={() => setLoaded(true)}
      />

      {!loaded && <Skeleton />}
    </div>
  );
};

export default Image;
