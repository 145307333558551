export const INIT_PAGINATION = {
  PAGE: 1,
  LIMIT: 20,
};

export const GLOBAL_EVENT = {
  RELOAD_ADMIN: 'Reload admin',
};

export const LEVEL_STEP = 5;
