export enum EAdminRole {
  Admin = 'admin',
  SuperAdmin = 'superadmin',
}

export enum EBeamReception {
  Enable = 'enable',
  Disable = 'disable',
}

export enum EKind {
  Main = 'main',
  Special = 'special',
}

export enum ESubKind {
  Energizing = 'energizing',
  Seasonal = 'seasonal',
  NatureSpirit = 'natureSpirit',
  CitySpirit = 'citySpirit',
  PreciousStones = 'preciousStones',
  Celebrities = 'celebrities',
  Painters = 'painters',
  Mystics = 'mystics',
  FictionalCharacters = 'fictionalCharacters',
  MysticalCreatures = 'mysticalCreatures',
  AnimalSpirit = 'animalSpirit',
}
