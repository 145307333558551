import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Upload,
  UploadProps,
} from 'antd';
import moment from 'moment';
import { ChromePicker } from 'react-color';

// Components
import Image from 'components/Image';
import CropImage from 'components/CropImage';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import {
  getAffirmation,
  getTypeDetail,
  removeTypeGif,
  removeTypeImage,
  removeTypeVideo,
  updateAffirmation,
  updateType,
  uploadTypeGif,
  uploadTypeImage,
  uploadTypeVideo,
} from 'store/global/action';

// Helpers
import { getUniqueKey } from 'helpers/common.helper';

// Enums
import { EKind, ESubKind } from 'enums/common.enum';

import './TypeDetail.scss';

const { Option } = Select;

const TypeDetail = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const params: any = useParams();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSelectColor, setShowSelectColor] = useState<boolean>(false);
  const [color, setColor] = useState<any>('#000');
  const [image, setImage] = useState<any>(null);
  const [showUploadImage, setShowUploadImage] = useState<boolean>(false);
  const [uploadVideoLoading, setUploadVideoLoading] = useState<boolean>(false);
  const [deleteVideoLoading, setDeleteVideoLoading] = useState<boolean>(false);
  // const [uploadGifLoading, setUploadGifLoading] = useState<boolean>(false);
  // const [deleteGifLoading, setDeleteGifLoading] = useState<boolean>(false);
  const [uploadImageLoading, setUploadImageLoading] = useState<boolean>(false);
  const [deleteImageLoading, setDeleteImageLoading] = useState<boolean>(false);

  const fetchData = async (id: string) => {
    try {
      let affirmation = '';

      try {
        const res: any = await unwrapResult(await dispatch(getAffirmation(id)));
        if (res) {
          affirmation = res.sentences
            .map(
              (sentence: string, index: number) => `${index + 1}. ${sentence}`
            )
            .join('\n');
        }
      } catch (e) {
        console.log(e);
      }

      const res: any = await unwrapResult(await dispatch(getTypeDetail(id)));
      res.affirmation = affirmation;
      setData(res);
    } catch (e) {
      console.log(e);
    }
  };

  const isMultipleRows = (text: string) => {
    return text.includes('\n') || text.includes('\r');
  };

  const onSubmit = async (values: any) => {
    const valid = await form.validateFields();
    if (!valid) return;

    const { affirmation, ...type } = values;

    if (affirmation && affirmation.trim() !== '') {
      try {
        let sentences;

        if (isMultipleRows(affirmation)) {
          const rows = affirmation.split('\n');
          sentences = rows.map((row: string) =>
            row.replace(/^\d+\.\s*/, '').trim()
          );
        } else {
          sentences = affirmation
            .match(/\d+\.\s([\s\S]+?)(?=\d+\.\s|$)/g)
            .map((sentence: string) =>
              sentence.replace(/^\d+\.\s*/, '').trim()
            );
        }

        if (sentences.length === 0) {
          return message.error('Can not detect affirmation');
        }

        await unwrapResult(
          await dispatch(
            updateAffirmation({
              id: params.id,
              values: { sentences },
            })
          )
        );
      } catch (e) {
        return message.error('Can not detect affirmation');
      }
    }

    try {
      setLoading(true);
      await unwrapResult(
        await dispatch(
          updateType({
            id: params.id,
            values: type,
          })
        )
      );
      message.success('Updated type successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleShowSelectColor = () => {
    setShowSelectColor(!showSelectColor);
  };

  const handleChoosingColor = (hexColor: string) => {
    setColor(hexColor);
  };

  const handleImageSelection = (data: any) => {
    setShowUploadImage(false);
    setImage(data);
  };

  const uploadVideoProps: UploadProps = {
    beforeUpload: (file: any) => {
      handleUploadVideo(file);
      return false;
    },
    showUploadList: false,
  };

  const handleUploadVideo = async (file: any) => {
    try {
      setUploadVideoLoading(true);
      await unwrapResult(
        await dispatch(
          uploadTypeVideo({
            id: params.id,
            file,
          })
        )
      );
      fetchData(params.id);
      message.success('Updated type video successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setUploadVideoLoading(false);
    }
  };

  const handleDeleteVideo = async () => {
    try {
      setDeleteVideoLoading(true);
      await unwrapResult(await dispatch(removeTypeVideo(params.id)));
      fetchData(params.id);
      message.success('Deleted type video successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteVideoLoading(false);
    }
  };

  // const uploadGifProps: UploadProps = {
  //   beforeUpload: (file: any) => {
  //     handleUploadGif(file);
  //     return false;
  //   },
  //   showUploadList: false,
  // };

  // const handleUploadGif = async (file: any) => {
  //   try {
  //     setUploadGifLoading(true);
  //     await unwrapResult(await dispatch(uploadTypeGif({
  //       id: params.id,
  //       file,
  //     })));
  //     fetchData(params.id);
  //     message.success('Updated type gif successfully');
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setUploadGifLoading(false);
  //   }
  // }

  // const handleDeleteGif = async () => {
  //   try {
  //     setDeleteGifLoading(true);
  //     await unwrapResult(await dispatch(removeTypeGif(params.id)));
  //     fetchData(params.id);
  //     message.success('Deleted type gif successfully');
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setDeleteGifLoading(false);
  //   }
  // }

  const uploadImageProps: UploadProps = {
    beforeUpload: (file: any) => {
      handleUploadImage(file);
      return false;
    },
    showUploadList: false,
  };

  const handleUploadImage = async (file: any) => {
    try {
      setUploadImageLoading(true);
      await unwrapResult(
        await dispatch(
          uploadTypeImage({
            id: params.id,
            file,
          })
        )
      );
      fetchData(params.id);
      message.success('Updated type image successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setUploadImageLoading(false);
    }
  };

  const handleDeleteImage = async () => {
    try {
      setDeleteImageLoading(true);
      await unwrapResult(await dispatch(removeTypeImage(params.id)));
      fetchData(params.id);
      message.success('Deleted type image successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteImageLoading(false);
    }
  };

  useEffect(() => {
    if (params && params.id) {
      fetchData(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (data) {
      setColor(data.color);

      if (data.image) {
        setImage(data.image);
      }
    }
  }, [data]);

  return (
    <>
      {data ? (
        <div className="content type-detail-content">
          <div className="type-detail-content__media">
            <h3>Image</h3>

            <div className="type-detail-content__media__content">
              <Image
                styles={{
                  width: '150px',
                  height: '150px',
                  borderRadius: '50%',
                }}
                source={image ? image : require('assets/img/default.jpeg')}
              />

              <div className="type-detail-content__media__content__actions">
                <Upload {...uploadImageProps}>
                  <Button
                    className="btn btn-primary"
                    loading={uploadImageLoading}
                    disabled={uploadImageLoading}
                  >
                    Upload
                  </Button>
                </Upload>

                {data.image && data.image !== '' ? (
                  <Button
                    className="btn btn-danger"
                    loading={deleteImageLoading}
                    disabled={deleteImageLoading}
                    onClick={handleDeleteImage}
                  >
                    Delete
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          <div className="type-detail-content__media">
            <h3>Video</h3>

            <div className="type-detail-content__media__content">
              {data.video && data.video !== '' ? (
                <video controls>
                  <source src={data.video} type="video/mp4" />
                </video>
              ) : (
                <Image
                  styles={{
                    width: '150px',
                    height: '150px',
                  }}
                  source={require('assets/img/default-video.png')}
                />
              )}

              <div className="type-detail-content__media__content__actions">
                <Upload {...uploadVideoProps}>
                  <Button
                    className="btn btn-primary"
                    loading={uploadVideoLoading}
                    disabled={uploadVideoLoading}
                  >
                    Upload
                  </Button>
                </Upload>

                {data.video && data.video !== '' ? (
                  <Button
                    className="btn btn-danger"
                    loading={deleteVideoLoading}
                    disabled={deleteVideoLoading}
                    onClick={handleDeleteVideo}
                  >
                    Delete
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          {/* <div className="type-detail-content__media">
            <h3>Gif</h3>

            <div className="type-detail-content__media__content">
              <Image
                styles={{
                  width: '150px',
                  height: '150px',
                  borderRadius: '50%'
                }}
                source={data.gif && data.gif !== '' ? data.gif : require('assets/img/default.jpeg')}
              />

              <div className="type-detail-content__media__content__actions">
                <Upload {...uploadGifProps}>
                  <Button
                    className="btn btn-primary"
                    loading={uploadGifLoading}
                    disabled={uploadGifLoading}
                  >Upload</Button>
                </Upload>

                {data.gif && data.gif !== '' ? (
                  <Button
                    className="btn btn-danger"
                    loading={deleteGifLoading}
                    disabled={deleteGifLoading}
                    onClick={handleDeleteGif}
                  >Delete</Button>
                ) : null}
              </div>
            </div>
          </div> */}

          <div className="type-detail-content__form">
            <Form
              name="basic"
              onFinish={onSubmit}
              autoComplete="off"
              layout="vertical"
              className="default"
              form={form}
              initialValues={data}
            >
              <Row>
                <Col md="6">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: 'Name is required' },
                      { min: 3, message: 'Name must be at least 3 characters' },
                      {
                        max: 100,
                        message: 'Name must be no more than 100 characters',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md="6">
                  <Form.Item
                    label="Color"
                    name="color"
                    rules={[
                      { required: true, message: 'Color is required' },
                      {
                        pattern: new RegExp(
                          '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'
                        ),
                        message: 'Please enter valid hex color',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <div className="pick-color">
                    <div
                      className="pick-color__square"
                      style={{ background: color }}
                    ></div>

                    <p onClick={handleShowSelectColor}>
                      {showSelectColor ? 'Close' : 'Choose color'}
                    </p>

                    {showSelectColor && (
                      <ChromePicker
                        color={color}
                        onChange={(color) => handleChoosingColor(color.hex)}
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Form.Item label="Description" name="description">
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Form.Item label="Affirmation" name="affirmation">
                    <Input.TextArea rows={10} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Item label="Type" name="kind">
                    <Select>
                      <Option value={EKind.Main}>Main</Option>
                      <Option value={EKind.Special}>Special</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col md="6">
                  <Form.Item label="Sub Type" name="subKind">
                    <Select>
                      <Option value={ESubKind.Energizing}>Energizing</Option>
                      <Option value={ESubKind.Seasonal}>Seasonal</Option>
                      <Option value={ESubKind.NatureSpirit}>
                        Nature Spirit
                      </Option>
                      <Option value={ESubKind.CitySpirit}>City Spirit</Option>
                      <Option value={ESubKind.PreciousStones}>
                        Precious Stones
                      </Option>
                      <Option value={ESubKind.Celebrities}>Celebrities</Option>
                      <Option value={ESubKind.Painters}>Painters</Option>
                      <Option value={ESubKind.Mystics}>Mystics</Option>
                      <Option value={ESubKind.FictionalCharacters}>
                        Fictional Characters
                      </Option>
                      <Option value={ESubKind.MysticalCreatures}>
                        Mystical Creatures
                      </Option>
                      <Option value={ESubKind.AnimalSpirit}>
                        Animal Spirit
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Item label="Level" name="level">
                    <Select>
                      {Array.from(Array(100).keys()).map((item, index) => (
                        <Option key={getUniqueKey()} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md="6">
                  <div className="static-form-item">
                    <label>Created At</label>
                    <Input
                      value={moment(data.createdAt).format('YYYY-MM-DD')}
                      disabled
                    />
                  </div>
                </Col>
              </Row>

              <Form.Item>
                <Button
                  className="btn btn-primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : null}

      <Modal
        title="Image Selection"
        open={showUploadImage}
        onCancel={() => setShowUploadImage(false)}
        footer={null}
      >
        <CropImage onSelect={handleImageSelection} />
      </Modal>
    </>
  );
};

export default TypeDetail;
