import { v4 } from 'uuid';
import { find } from 'lodash';
import type { RcFile } from 'antd/es/upload/interface';

// Core
import storage from 'core/storage';

// Config
import COUNTRIES from 'config/countries.json';

// Constants
import { LEVEL_STEP } from 'constants/common.constant';

export const getUniqueKey = () => {
  return v4();
};

export const setToken = (token: string) => {
  storage.set('token', { token });
};

export const getToken = () => {
  const res = storage.get('token');
  return res ? res.token : null;
};

export const removeToken = () => {
  storage.remove('token');
};

export const cleanObject = (data: any) => {
  if (typeof data === 'object' && Object.keys(data).length) {
    for (const key in data) {
      if (data[key] === undefined) {
        delete data[key];
      }
    }
  }
  return data;
};

export const getFlag = (countryCode: string) => {
  const res = find(COUNTRIES.data, { code: countryCode });
  return res?.emoji;
};

export const getLevel = (count: number) => {
  return Math.floor(count / LEVEL_STEP);
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const isBase64Image = (source: string) => {
  return source && source.includes(';base64,') ? true : false;
};

export const getMimeType = (base64: string) => {
  return base64.split(';')[0].split(':')[1];
};

export const getMimeTypeExtension = (type: string) => {
  const extension = type.split('/')[1];
  if (extension.toString() === 'svg+xml') {
    return 'svg';
  }
  return extension;
};

export const convertBase64ToFile = async (base64: string) => {
  const type = getMimeType(base64);
  const extension = getMimeTypeExtension(type);
  const blob = await fetch(base64).then((res) => res.blob());
  return new File([blob], `${getUniqueKey()}.${extension}`, { type });
};

export const createImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = src;
  });
};

export const getCroppedImg = async (src: string, crop: any) => {
  const image: any = await createImage(src);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = 250;
  canvas.height = 250;

  ctx?.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/png');
  });
};

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
