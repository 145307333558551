import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { Button, Input, message, Modal, Select } from 'antd';
import { debounce, remove } from 'lodash';

// Config
import COUNTRIES from 'config/countries.json';

// Components
import Loading from 'components/Loading';

// Interfaces
import { IQueryParam } from 'interfaces/common.interface';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import { getUser, removeUser } from 'store/global/action';

// Constants
import { INIT_PAGINATION } from 'constants/common.constant';

// Helpers
import { getFlag, getLevel, getUniqueKey } from 'helpers/common.helper';

const { Option } = Select;

const User = () => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IQueryParam>({
    page: INIT_PAGINATION.PAGE,
    limit: INIT_PAGINATION.LIMIT,
  });
  const [pagination, setPagination] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showDeleteUser, setShowDeleteUser] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(null);

  const fetchData = async () => {
    try {
      setLoading(true);

      const res: any = await unwrapResult(await dispatch(getUser(params)));

      if (res && res.items && res.pagination) {
        setData([...data, ...res.items]);
        setPagination(res.pagination);
      } else {
        setData([...[]]);
        setPagination(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreData = () => {
    if (params.page && params.page < pagination.totalPages) {
      setParams({ ...params, page: params.page + 1 });
    }
  };

  const handleRemoveUser = (id: string) => {
    setDeleteId(id);
    setShowDeleteUser(true);
  };

  const resetRemoveModal = () => {
    setDeleteId(null);
    setShowDeleteUser(false);
  };

  const handleCancelRemoveUser = () => {
    resetRemoveModal();
  };

  const handleOkRemoveUser = async () => {
    try {
      await unwrapResult(await dispatch(removeUser(deleteId)));
      remove(data, (item) => item._id === deleteId);
      setData([...data]);
      message.success('Deleted user successfully');
      resetRemoveModal();
    } catch (e) {
      console.log(e);
    }
  };

  const resetData = () => {
    setData([...[]]);
    setPagination(null);
  };

  const searching = (e: any) => {
    resetData();

    if (e.target.value.trim() !== '') {
      setParams({
        ...params,
        keyword: e.target.value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    } else {
      setParams({
        ...params,
        keyword: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    }
  };

  const selectLevel = (value: any) => {
    resetData();

    if (value !== '') {
      setParams({
        ...params,
        level: value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    } else {
      setParams({
        ...params,
        level: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    }
  };

  const selectCountry = (value: any) => {
    resetData();

    if (value !== '') {
      setParams({
        ...params,
        country: value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    } else {
      setParams({
        ...params,
        country: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT,
      });
    }
  };

  useEffect(() => {
    if (params) {
      fetchData();
    }
  }, [params]);

  useEffect(() => {
    if (pagination) {
      if (pagination.currentPage < pagination.totalPages) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [pagination]);

  return (
    <>
      <div className="content user-content">
        <Row>
          <div className="multiple-fields">
            <div className="multiple-fields__item">
              <Input
                className="default"
                placeholder="Search..."
                allowClear
                onChange={debounce(searching, 1000)}
              />
            </div>

            <div className="multiple-fields__item">
              <Select
                placeholder="Select level"
                className="default"
                allowClear
                onChange={selectLevel}
              >
                {Array.from(Array(100).keys()).map((item, index) => (
                  <Option key={getUniqueKey()} value={item}>
                    Level {item}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="multiple-fields__item">
              <Select
                placeholder="Select country"
                className="default"
                allowClear
                onChange={selectCountry}
              >
                {COUNTRIES.data.map((item, index) => (
                  <Option key={getUniqueKey()} value={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </Row>

        <Row>
          <Col md="12">
            {data && data.length ? (
              <Card>
                <CardBody>
                  <InfiniteScroll
                    dataLength={data.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={null}
                    endMessage={
                      <p className="all-items-txt">You have seen all items</p>
                    }
                  >
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Country</th>
                          <th>Level</th>
                          <th>Created At</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((item, index) => (
                          <tr key={getUniqueKey()}>
                            <td className="avatar">
                              <img
                                alt="photo"
                                src={
                                  item.thumbnail
                                    ? item.thumbnail.small
                                    : require('assets/img/avatar.png')
                                }
                              />
                            </td>

                            <td className="link">
                              <a href={`user/${item._id}`}>{item.fullName}</a>
                            </td>

                            <td>{item.email}</td>

                            <td className="flag">
                              {item.country ? getFlag(item.country) : ''}
                            </td>

                            <td>{getLevel(item.beamTimes)}</td>

                            <td>
                              {moment(item.createdAt).format('YYYY-MM-DD')}
                            </td>

                            <td className="text-right action link">
                              <a href={`user/${item._id}`}>
                                <i className="fas fa-pen"></i>
                              </a>

                              <i
                                className="fas fa-trash"
                                onClick={() => handleRemoveUser(item._id)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </CardBody>
              </Card>
            ) : (
              <p className="all-items-txt">No items.</p>
            )}

            {loading && <Loading />}
          </Col>
        </Row>
      </div>

      <Modal
        title="Delete"
        open={showDeleteUser}
        onOk={handleOkRemoveUser}
        onCancel={handleCancelRemoveUser}
        footer={[
          <Button
            key="back"
            className="btn modal-btn"
            onClick={handleCancelRemoveUser}
          >
            Cancel
          </Button>,
          <Button
            className="btn btn-primary modal-btn"
            key="submit"
            onClick={handleOkRemoveUser}
          >
            OK
          </Button>,
        ]}
      >
        <p>Do you want to remove this user?</p>
      </Modal>
    </>
  );
};

export default User;
