import { createAction } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'store/thunk';
import HTTP from 'core/http';

// Interfaces
import { IEvent } from 'interfaces/common.interface';

// Helpers
import { cleanObject } from 'helpers/common.helper';

export const event = createAction<IEvent | any>('global/event');
export const storeMe = createAction<IEvent | any>('global/storeMe');

export const emitEvent = (data: IEvent | any) => (dispatch: any) => {
  dispatch(event(data));
  setTimeout(() => {
    dispatch(event(null));
  }, 1000);
};

export const getCurrentUser = createAppAsyncThunk(
  'global/currentUser',
  async () => {
    const res = await HTTP.get('admin/me');
    return res;
  }
);

export const login = createAppAsyncThunk('global/login', async (data: any) => {
  const res = await HTTP.post('admin/login', cleanObject(data));
  return res;
});

// Admin
export const getAdmin = createAppAsyncThunk(
  'global/getAdmin',
  async (params: any) => {
    const res = await HTTP.get('admin', { params });
    return res;
  }
);

export const createAdmin = createAppAsyncThunk(
  'global/createAdmin',
  async (data: any) => {
    const res = await HTTP.post('admin', cleanObject(data));
    return res;
  }
);

export const removeAdmin = createAppAsyncThunk(
  'global/removeAdmin',
  async (id: string) => {
    const res = await HTTP.delete(`admin/${id}`);
    return res;
  }
);

export const getAdminDetail = createAppAsyncThunk(
  'global/getAdminDetail',
  async (id: string) => {
    const res = await HTTP.get(`admin/${id}`);
    return res;
  }
);

export const updateAdmin = createAppAsyncThunk(
  'global/updateAdmin',
  async (data: any) => {
    const res = await HTTP.patch(`admin/${data.id}`, cleanObject(data.values));
    return res;
  }
);

// User
export const getUser = createAppAsyncThunk(
  'global/getUser',
  async (params: any) => {
    const res = await HTTP.get('user', { params });
    return res;
  }
);

export const removeUser = createAppAsyncThunk(
  'global/removeUser',
  async (id: string) => {
    const res = await HTTP.delete(`user/${id}`);
    return res;
  }
);

export const getUserDetail = createAppAsyncThunk(
  'global/getUserDetail',
  async (id: string) => {
    const res = await HTTP.get(`user/${id}`);
    return res;
  }
);

export const updateUser = createAppAsyncThunk(
  'global/updateUser',
  async (data: any) => {
    const res = await HTTP.patch(`user/${data.id}`, cleanObject(data.values));
    return res;
  }
);

export const uploadUserAvatar = createAppAsyncThunk(
  'global/uploadUserAvatar',
  async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);
    const res = await HTTP.post(`user/${data.id}/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  }
);

export const removeUserAvatar = createAppAsyncThunk(
  'global/removeUserAvatar',
  async (id: string) => {
    const res = await HTTP.delete(`user/${id}/avatar`);
    return res;
  }
);

// Type
export const getAllType = createAppAsyncThunk(
  'global/getAllType',
  async (params: any) => {
    const res = await HTTP.get('type/all', { params });
    return res;
  }
);

export const removeType = createAppAsyncThunk(
  'global/removeType',
  async (id: string) => {
    const res = await HTTP.delete(`type/${id}`);
    return res;
  }
);

export const getTypeDetail = createAppAsyncThunk(
  'global/getTypeDetail',
  async (id: string) => {
    const res = await HTTP.get(`type/${id}`);
    return res;
  }
);

export const updateType = createAppAsyncThunk(
  'global/updateType',
  async (data: any) => {
    const res = await HTTP.patch(`type/${data.id}`, cleanObject(data.values));
    return res;
  }
);

export const uploadTypeVideo = createAppAsyncThunk(
  'global/uploadTypeVideo',
  async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);
    const res = await HTTP.post(`type/${data.id}/video`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  }
);

export const removeTypeVideo = createAppAsyncThunk(
  'global/removeTypeVideo',
  async (id: string) => {
    const res = await HTTP.delete(`type/${id}/video`);
    return res;
  }
);

export const uploadTypeGif = createAppAsyncThunk(
  'global/uploadTypeGif',
  async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);
    const res = await HTTP.post(`type/${data.id}/gif`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  }
);

export const removeTypeGif = createAppAsyncThunk(
  'global/removeTypeGif',
  async (id: string) => {
    const res = await HTTP.delete(`type/${id}/gif`);
    return res;
  }
);

export const uploadTypeImage = createAppAsyncThunk(
  'global/uploadTypeImage',
  async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);
    const res = await HTTP.post(`type/${data.id}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  }
);

export const removeTypeImage = createAppAsyncThunk(
  'global/removeTypeImage',
  async (id: string) => {
    const res = await HTTP.delete(`type/${id}/image`);
    return res;
  }
);

export const getAffirmation = createAppAsyncThunk(
  'global/getAffirmation',
  async (id: string) => {
    const res = await HTTP.get(`affirmation/type/${id}`);
    return res;
  }
);

export const updateAffirmation = createAppAsyncThunk(
  'global/updateAffirmation',
  async (data: any) => {
    const res = await HTTP.post(
      `affirmation/type/${data.id}`,
      cleanObject(data.values)
    );
    return res;
  }
);

// System Log
export const getSystemLog = createAppAsyncThunk(
  'global/getSystemLog',
  async (params: any) => {
    const res = await HTTP.get('system-log', { params });
    return res;
  }
);
